<template>
  <div class="pdpa-container container pt-3 pb-2">
    <img
      src="@/assets/icon/back-circle.svg"
      height="26px"
      width="26px"
      @click="goBack"
    />
    <div class="button-container mt-2">
      <button
        type="button"
        class="btn btn-light mt-3"
        @click="openModal()"
      >
        ประกาศว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล
      </button>
    </div>
    <b-modal id="consent" hide-footer centered>
      <template v-slot:modal-header-close>
        <i class="close-modal icon-cancel-circled-outline" />
      </template>
      <div class="d-block text-center" v-if="consentDetail">
        <div id="content" v-html="consentDetail && consentDetail.content && consentDetail.content.replaceAll('101%','100%')" @scroll="handleScroll()">
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data: () => ({
    consentList: [
      {
        title: "เงื่อนไขและข้อตกลงในการใช้บริการ",
        detail: {
          title: "Title",
          content: "Content",
        },
      },
      {
        title: "ประกาศว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล",
        detail: {
          title: "Title",
          content: "Content",
        },
      },
    ],
    consentDetail: null,
    scrollEnd: false,
  }),
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async fetchPDPA() {
      return await fetch(`${process.env.VUE_APP_API_URL}/user/consents/pdpa/complete`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
    },
    async openModal() {
      try {
        window.loading(true);
        const res = await this.fetchPDPA();
        console.log("fetchPDPA status:", res.status);
        let data =[]
        switch (res.status) {
          case 200: {
            data = await res.json()
            this.consentDetail = data[0]
            this.$bvModal.show("consent");
            return;
          }
          case 401:
            console.log("case 401");
            break;
          default: {
            console.log("no case");
            throw "no case";
          }
        }
      } catch (error) {
        console.log(error);
        window.$error();
      } finally {
        window.loading(false);
      }
    },
    handleScroll() {
      const content = document.getElementById("content");

      if (
        content.scrollTop + content.clientHeight >=
        content.scrollHeight - 100
      ) {
        this.scrollEnd = true;
      }
    },
  },
  mounted() {
    window.loading(false);
  },
};
</script>

<style lang="scss" scoped>
$light-blue: #eaf2ff;

.pdpa-container {
  background: $light-blue;
  height: 100%;
  .button-container {
    button {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      width: 100%;
    }
  }
}
</style>
